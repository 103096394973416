import {createStore} from 'vuex'

// const toasts : any[] = [];
export default createStore({
    state: {
        products: {},
        searchParams: {},
        toasts: []
    },
    getters:{},
    mutations: {
        UPDATE_PRODUCTS(state, value) {
          state.products = value;
        },
        APPEND_PRODUCTS(state, value) {
            console.log(state.products, value);
            state.products.products = [...state.products.products, ...value.products]
            console.log(state.products, value);
        },
        UPDATE_SEARCH_PARAMS(state, value) {
            state.searchParams = value;
        },
        ADD_TOAST(state, value) {
            state.toasts.push(value);
        },
        CLEAR_TOAST(state) {
            state.toasts.shift();
        }
    },
    actions: {
        async updateProducts({commit}, payload) {
            commit('UPDATE_PRODUCTS', payload);
        },
        async appendProducts({commit}, payload) {
            commit('APPEND_PRODUCTS', payload);
        },
        async updateSearchParams({commit}, payload) {
            commit('UPDATE_SEARCH_PARAMS', payload);
        },
        async addToast({commit}, payload) {
            commit('ADD_TOAST', payload)
        },
        async clearToast({commit}, payload) {
            commit('CLEAR_TOAST', payload)
        }
    }
})