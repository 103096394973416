<template>
  <div>
    <div class="content">
      <span class='text-white text-2xl space-y-4'>Welcome to Giftly.ai: The AI Gift Finder and Idea Generator</span>
      <br>
      <p>Discover unique and personalized gifts effortlessly – and it's completely free.</p>
      <p>Giftly.ai is your premier AI gift finder, a tool that transforms the way you discover gifts for any individual and occasion. Using the power of AI, we offer cool gift ideas that cater to the personal tastes and interests of the recipient, ensuring you can find the perfect gift without the need for registration or a credit card.</p>

      <h2 class='text-white text-2xl space-y-4'>How to Use Giftly.ai</h2>
      <ol>
        <li><strong>Gender Selection:</strong> Utilize our AI gift finder to select gifts tailored to the recipient's gender, ensuring relevancy and personalization.</li>
        <li><strong>Age Specification:</strong> The gift ideas AI considers age to ensure appropriate recommendations, making it an ideal gift generator for any demographic.</li>
        <li><strong>Interests Input:</strong> Detail up to three interests, and our gift idea generator based on interests will curate a list of potential gifts that resonate with the recipient's passions.</li>
        <li><strong>Budget Choice:</strong> Set your budget using our intuitive interface – whether you're looking for gift ideas under $50, between $50-150, or over $150, Giftly.ai is your go-to gift tool.</li>
        <li><strong>Discovery:</strong> Hit 'Find' and let our perfect gift generator unveil a selection of gifts from Amazon, each with a secure link for an easy and trusted shopping experience.</li>
      </ol>

      <h2 class='text-white text-2xl space-y-4'>Seamless Discovery and Trusted Shopping</h2>
      <p>After entering your criteria, press 'Find' and watch as Giftly.ai instantly populates a range of gift suggestions tailored to your specifications. Each handpicked option comes with a secure direct link to Amazon, ensuring a trusted and seamless shopping experience. Our use of affiliate links is what keeps Giftly.ai free for all users. It's our way of providing value without any cost to you, while maintaining the highest standards of security and user trust.</p>

      <h2 class='text-white text-2xl space-y-4'>Explore Even More Gift Ideas</h2>
      <p>Didn't find the perfect gift yet? Click the "View More" button for an extended selection of gift ideas. Continue to explore an enriched collection of choices that ensure you find exactly what you're looking for. With Giftly.ai, the possibilities are endless and the perfect gift is just around the corner.</p>

      <h2 class='text-white text-2xl space-y-4'>Benefits of Using Giftly.ai: Your AI-Powered Gift Tool</h2>
      <ul>
        <li><strong>Diverse Selection:</strong> From present finders for the holiday season to creative gift ideas for me, our AI has it all.</li>
        <li><strong>User Experience:</strong> Navigate our platform with ease – we're the cool gift ideas AI you've been looking for.</li>
        <li><strong>Cost-Efficient:</strong> Completely free to use, Giftly.ai is the gift assistant that values your experience and your wallet.</li>
      </ul>
    </div>

    <div class="social-share-buttons background">
      <a href="https://www.facebook.com/sharer/sharer.php?u=https://giftly.ai/" class="social-share-button facebook" target="_blank">Share on Facebook</a>
      <a href="https://twitter.com/intent/tweet?url=https://giftly.ai/&text=Check%20out%20this%20amazing%20AI-powered%20gift%20finder!" class="social-share-button twitter" target="_blank">Share on Twitter</a>
      <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://giftly.ai/&title=Find%20the%20Perfect%20Gift%20with%20Giftly.ai&summary=Discover%20personalized%20gift%20ideas%20instantly%20using%20AI.&source=LinkedIn" class="social-share-button linkedin" target="_blank">Share on LinkedIn</a>
      <a href="https://wa.me/?text=Check%20out%20Giftly.ai,%20an%20amazing%20AI-powered%20gift%20finder:%20https://giftly.ai/" class="social-share-button whatsapp" target="_blank">Share on WhatsApp</a>
    </div>
  </div>
</template>
<style>
/* Container for the social share buttons */
.social-share-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px; /* Adds 20px margin around the div */
  padding: 20px; /* Optional: Adds space inside the div, around the buttons */
}

/* Base style for all buttons */
.social-share-button {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Facebook Button Style */
.social-share-button.facebook {
  background-color: #3b5998;
}

.social-share-button.facebook:hover {
  background-color: #2d4373;
}

/* Twitter Button Style */
.social-share-button.twitter {
  background-color: #1da1f2;
}

.social-share-button.twitter:hover {
  background-color: #0d95e8;
}

/* LinkedIn Button Style */
.social-share-button.linkedin {
  background-color: #0077b5;
}

.social-share-button.linkedin:hover {
  background-color: #005582;
}

/* WhatsApp Button Style */
.social-share-button.whatsapp {
  background-color: #25d366;
}

.social-share-button.whatsapp:hover {
  background-color: #1da851;
}

/*.content {*/
/*  color: white; !* This sets the text color to white for better readability *!*/
/*  padding: 20px; !* Optional: Adds space inside the div *!*/
/*  margin: 20px; !* Optional: Adds space outside the div *!*/
/*  border-radius: 10px; !* Optional: Adds rounded corners to the div *!*/
/*}*/

.content {
  background-color: rgb(55, 65, 81);
  color: white;
  padding: 20px;
  margin: 20px auto; /* Centers the content div and adds margin around it */
  border-radius: 10px;
  max-width: 800px; /* Sets a maximum width for larger screens */
  box-sizing: border-box; /* Include padding and border in the width and height */
  text-align: left;
}

/* Responsive media query for smaller screens */
@media (max-width: 768px) {
  .content {
    margin: 10px; /* Smaller margin on small screens */
    padding: 10px; /* Smaller padding on small screens */
  }
}

/* Base styles */
.social-share-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows buttons to wrap onto the next line on small screens */
  gap: 10px;
  margin: 20px;
}

.social-share-button {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1; /* Allows buttons to grow and fill the space */
  min-width: 120px; /* Minimum width for buttons */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Specific styles */
/* ... (Include other specific styles for each social media button here) ... */

/* Responsive media query */
@media (max-width: 768px) {
  .social-share-buttons {
    flex-direction: column; /* Stack buttons vertically on small screens */
  }

  .social-share-button {
    text-align: center;
    width: 100%; /* Full width buttons on small screens */
    box-sizing: border-box; /* Ensures padding is included in width */
  }
}

h2 {
  margin-top: 20px; /* Adds space above the heading */
  margin-bottom: 10px; /* Adds space below the heading */
  font-size: 24px; /* Adjust font size as needed for mobile */
}

/* Add some space between paragraphs */
p {
  margin-top: 10px; /* Adds space above the paragraph */
  margin-bottom: 10px; /* Adds space below the paragraph */
  font-size: 16px; /* Adjust font size as needed for mobile */
}

/* Adjust the list spacing */
ol {
  margin-top: 10px; /* Adds space above the list */
  margin-bottom: 10px; /* Adds space below the list */
}

/* Adjust spacing for list items */
li {
  margin-bottom: 8px; /* Adds space below each list item */
}

/* Ensure that the content div is not too wide on mobile */
.content {
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content {
    padding: 15px; /* Slightly smaller padding on smaller screens */
    margin: 10px;
  }
  h2 {
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 22px; /* Slightly smaller font size on smaller screens */
  }
  p, ol, li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
</style>