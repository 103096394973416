<template>
  <div @click='router.push("/")' class='flex flex-row justify-center items-center space-x-2 m-8 cursor-pointer'>
    <span class='text-white text-4xl'>giftly.ai</span>
    <MagnifyingGlassIcon class='w-8 h-8 text-indigo-500'/>
  </div>
</template>
<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import {useRouter} from 'vue-router';

const router = useRouter();
</script>