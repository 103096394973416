import { createWebHistory, createRouter } from 'vue-router';
import HomeView from '@/views/HomeView';
import TermsView from '@/views/TermsView';
import PrivacyView from '@/views/PrivacyView';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: TermsView,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyView,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;