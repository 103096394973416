<template>
  <div class='flex justify-center w-full'>
    <div class='grid h-auto w-3/4 grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 lg:gap-8'>
      <div v-for='(product, index) in state.products.products' :key='index' class='flex justify-center items-center my-2 lg:justify-start lg:items-start'>
        <div class='flex flex-col'>
          <a :href='product.product_url' target='_blank' class='flex text-white font-bold max-w-72'>{{ productName(product.name) }}</a>
          <a :href='product.product_url' target='_blank' class='flex justify-center'>
            <img class='rounded-2xl' :src=product.image_url width='300' />
          </a>
          <button @click='openProduct(product)' type="button" class="w-full mt-2 rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400">
            View on Amazon
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {reactive, watch } from 'vue';
import store from '@/store/index.ts';

watch(() => store.state.products, function() {
  handleResponseReceived();
});

const state = reactive({
  products: []
});

async function handleResponseReceived() {
  state.products = store.state.products;
}

function openProduct(product) {
  window.open(product.product_url, '__blank');
}

function productName(name) {
  return name.substring(0, 50) + ' ...see more';
}
</script>