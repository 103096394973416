<template>
  <div class='flex justify-center flex-col m-8 mt-0 space-y-8'>
    <h1 class='text-white text-2xl font-bold'>
      The AI-Powered Gift Finder
    </h1>
    <Listbox class='flex flex-col justify-center items-center' as="div" v-model="state.selectedGender">
      <ListboxLabel class="block text-sm font-medium leading-6 text-white">I'm looking for a gift for a</ListboxLabel>
      <div class="relative mt-2 w-52">
        <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span class="block truncate">{{ state.selectedGender.name }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
        </ListboxButton>
        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="item in state.gender" :key="item.id" :value="item" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ item.name }}</span>

                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <Listbox as="div" class='flex flex-col justify-center items-center' v-model="state.selectedAge">
      <ListboxLabel class="block text-sm font-medium leading-6 text-white">that is</ListboxLabel>
      <div class="relative mt-2 mx-4 w-52">
        <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span class="block truncate">{{ state.selectedAge }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="index in 100" :key="index" :value='index' v-slot="{ active, selected }">
              <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ index}}</span>

                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
      <ListboxLabel class="block text-sm font-medium leading-6 text-white">years old</ListboxLabel>
    </Listbox>
    <div class='flex flex-col justify-center items-center'>
      <label class="block text-sm font-medium text-white">Interests <span class='text-xs font-light text-gray-100'>optional (max 3)</span></label>
      <div class="mt-1 w-52">
        <input v-model='state.selectedInterest' @keyup.enter='appendInterest' type="text" name="keywords" id="keywords" class="block w-full p-3 rounded-md border-gray-300 shadow-sm outline-0 sm:text-sm" placeholder="Chess" />
      </div>
      <div>
        <span v-for='(interest, index) in state.interests' :key='index' class="inline-flex items-center rounded-full bg-indigo-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-indigo-700 my-2 mx-1">
                    {{ interest }}
          <button type="button" class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 outline-0">
            <span class="sr-only">Remove large option</span>
            <svg @click='removeInterest(index)' class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
      </div>
    </div>
    <div class='flex flex-col justify-center items-center'>
      <span class="isolate inline-flex rounded-md shadow-sm">
        <button @click='setPrice(1)' type="button" :class="[state.selectedPrice === 1 ? 'bg-indigo-500 text-white' : 'bg-white text-indigo-500', 'relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-light focus:z-10']">$</button>
        <button @click='setPrice(2)' type="button" :class="[state.selectedPrice === 2 ? 'bg-indigo-500 text-white' : 'bg-white text-indigo-500', 'relative -ml-px inline-flex items-center px-3 py-2 text-sm font-light focus:z-10']">$$</button>
        <button @click='setPrice(3)' type="button" :class="[state.selectedPrice === 3 ? 'bg-indigo-500 text-white' : 'bg-white text-indigo-500', 'relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-light focus:z-10']">$$$</button>
      </span>
    </div>
    <div class='flex justify-center w-full'>
      <button :disabled='state.loading' @click='find' type="button" class="w-52 flex justify-center rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
        <span v-if='!state.loading'>Find</span>
        <span v-if='state.loading'>Searching ...</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import axios from 'axios';
import store from '@/store/index.ts';

const state = reactive({
  selectedAge: 18,
  selectedGender: { id: 2, name: 'Female', value: 'female' },
  selectedInterest: '',
  selectedPrice: 2,
  interests: [],
  gender: [
    { id: 1, name: 'Male', value: 'male' },
    { id: 2, name: 'Female', value: 'female' },
    { id: 3, name: 'Non-binary', value: null }
  ],
  loading: false
});

async function find() {
  state.loading = true;
  appendInterest();
    const data = {
      'age': state.selectedAge,
      'gender': state.selectedGender.value,
      'interests': state.interests,
      'price': state.selectedPrice
    }
    await store.dispatch('updateSearchParams', data);
    await axios.create({
      baseURL: process.env.VUE_APP_SERVER_URL,
      withCredentials: true,
    }).post('products/find', data)
      .then(async (res) => {
        await store.dispatch('updateProducts', res.data);
      })
      .catch((error) => {
        console.log(error)
      })

  state.loading = false;
}

function appendInterest() {
  if (state.selectedInterest.length > 0 && state.interests.length < 3) {
    state.selectedInterest = state.selectedInterest.replace(/ +(?= )/g,'');
    state.interests.push(state.selectedInterest);
  }

  state.selectedInterest = '';
}

function removeInterest(index) {
  state.interests.splice(index, 1);
}

function setPrice(price) {
  state.selectedPrice = price;
}
</script>