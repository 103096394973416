<template>
  <div class='text-white w-3/4 lg:w-1/2 text-left mb-24 lg:mb-8'>
    <p class='text-2xl font-bold'>Terms & Conditions</p>
    <br>
    <p class='font-bold'>Introduction</p>

    <p>Welcome to Giftly.ai ("our Website," "we," "us," or "our"). These Terms of Use ("Terms") govern your access to and use of our website. Your agreement to abide by these Terms is a condition of your use of the Website, and your access to and use of the Website signifies your acceptance of these Terms. If you do not agree with these Terms, you are not authorized to use or access our Website.</p>
    <br>
    <p class='font-bold'>Access to Our Website</p>
    <p>We reserve the unilateral right to amend, suspend or terminate our Website, or any service provided therein, at our discretion without any prior notice. We will not be held liable for any losses or inconveniences incurred as a result of such modifications, suspension, or termination of the Website or any part thereof.</p>
    <br>
    <p class='font-bold'>Intellectual Property Rights</p>
    <p>The Website, its content, features, and functionality, including, without limitation, all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof, are owned by Giftly.ai, its licensors, or other providers of such material and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
    <br>
    <p class='font-bold'>License to Use and Restrictions</p>

    <p>Your access to our Website is granted under a limited, revocable, non-exclusive, and non-transferable license. Under this license:</p>
    <p>You may view and use the material and content for personal, non-commercial use only.
      You are prohibited from modifying, copying, distributing, transmitting, displaying publicly, creating derivative works from, or selling any material or content from our Website.
      You may not attempt to reverse-engineer or attempt to extract the source code of our software, unless applicable laws prohibit these restrictions.
      You may not transfer any materials or content from our Website to another person or "mirror" the materials or content on any other server.
      This license may be terminated by us at any time. Upon termination, you must cease all use of our Website and destroy any downloaded materials.
    </p>
    <br>
    <p class='font-bold'>Affiliate Disclosure</p>
    <p>Giftly.ai participates in affiliate programs and may refer users to purchase products from various third-party companies. As an affiliate, we may earn commissions from certain purchases made via the affiliate links on our Website. By accessing and using our Website, you acknowledge and consent to our participation in these affiliate programs.</p>
    <br>
    <p class='font-bold'>Disclaimer</p>
    <p>The materials and content on Giftly.ai are provided "as is" and without warranties of any kind, either express or implied. Giftly.ai disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>
    <br>
    <p class='font-bold'>Limitation of Liability</p>
    <p>In no event shall Giftly.ai, its licensors, its suppliers, or any third parties mentioned on the Website be liable for any damages whatsoever (including, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption) resulting from the use or inability to use the Website or the material on the Website.</p>
    <br>
    <p class='font-bold'> Links to Third-Party Websites</p>
    <p>Our Website may contain links to third-party websites. These links are provided solely for your convenience and not as an endorsement by us of the content on such third-party websites. We are not responsible for the content of linked third-party sites and do not make any representations regarding the content or accuracy of materials on such third-party websites.</p>
    <br>
    <p class='font-bold'>Changes to the Terms of Use</p>
    <p>We reserve the right to amend these Terms at any time and at our discretion. Your continued use of our Website following any such changes constitutes acceptance of the revised Terms.</p>
    <br>
    <p class='font-bold'>Governing Law and Jurisdiction</p>
    <p>These Terms are governed by and will be interpreted in accordance with the laws of the United States, without regard to any choice of law provisions. You agree that any dispute or claim arising out of or relating to these Terms will be resolved by the courts of the United States.</p>
    <br>
    <p class='font-bold'>Contact Information</p>
    <p>If you have any questions or concerns regarding these Terms or our Website, please contact us at:</p>
    <br>
    <p>LightWeb Media <br>
      3410 Belle Chase Way, Ste 600 <br>
      Lansing, MI 48911
    </p>
  </div>
</template>