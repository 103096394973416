<template>
  <div class='text-gray-100 text-xs'>
    <div class='flex space-x-5'>
      <a href='/'>Home</a>
      <a href='/terms' target='_blank'>Terms & Conditions</a>
      <a href='/privacy' target='_blank'>Privacy Policy</a>
    </div>

    <div class='text-gray-400 mt-2'>
      Copyright &copy; {{ year }}
    </div>
  </div>
</template>
<script setup>

const year = new Date().getFullYear()
</script>