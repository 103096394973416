<template>
  <div v-if='productsLen' class='flex justify-center flex-col m-8 space-y-8'>
    <button :disabled='state.loading' @click='loadMore' type="button" class="w-full mt-2 rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400">
      <span v-if='!state.loading'>View more</span>
      <span v-if='state.loading'>Searching ...</span>
    </button>
  </div>
</template>
<script setup>
import store from '@/store/index.ts';
import axios from 'axios';
import {computed, reactive} from 'vue';

const productsLen = computed(() => {
  if (!Object.keys(store.state.products).length) {
    return false;
  }
  return store.state.products.products.length > 0;
})

const state = reactive({
  loading: false
});

async function loadMore() {
  let data = store.state.searchParams;
  data['prev_response'] = store.state.products.response;
  state.loading = true;
  await axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    withCredentials: true,
  }).post('products/find', data)
    .then(async (res) => {
      console.log('res', res.data);
      await store.dispatch('appendProducts', res.data);
    })
    .catch((error) => {
      console.log(error)
    })

  state.loading = false;
}
</script>