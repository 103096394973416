<template>
  <div class='text-white w-3/4 lg:w-1/2 text-left mb-24 lg:mb-8'>
    <p class='text-2xl'><strong>Privacy Policy</strong></p>
    <br>
    <p><strong>Introduction</strong></p>
    <p>Giftly.ai ("we," "us," or "our") values and respects your privacy. This Privacy Policy (the "Policy") outlines our commitment to safeguarding your privacy by detailing our policies and practices regarding the collection, use, security, and disclosure of information we gather from you or that you provide when you visit our website, <a href="http://Giftly.ai">http://Giftly.ai</a> (the "Website").</p>
    <p>This Policy applies to data we collect:</p>
    <ul>
      <li>On our Website</li>
      <li>In email, text, or other electronic communication between you and our Website.</li>
    </ul>
    <p>This Policy does not extend to any information collected offline or through channels other than our Website. By accessing or using our Website, you consent to this Policy. If you disagree with our practices as described in this Policy, we recommend that you do not use our Website.</p>
    <br>
    <p><strong>Information Collection</strong></p>
    <p>We gather various kinds of information from and about users of our Website, including anonymous data like IP addresses, which support us in understanding our Website's usage patterns. Please note, we do not collect personally identifiable information, such as names or email addresses.</p>
    <br>
    <p><strong>Cookies</strong></p>
    <p>Cookies are small files saved on your computer's hard drive. We utilize cookies on our Website to track affiliate commissions as we refer users to third-party companies. These cookies do not contain personal information but are intended solely for commission tracking. By using our Website, you consent to the use of these cookies.</p>
    <br>
    <p><strong>Utilization of Information</strong></p>
    <p>The information we gather from you, including any anonymous data, is used for the following purposes:</p>
    <ul>
      <li>To deliver and maintain our Website and its contents to you</li>
      <li>To enhance the browsing experience on our Website</li>
      <li>To track and manage affiliate commissions generated through our Website</li>
    </ul>
    <br>
    <p><strong>Information Disclosure</strong></p>
    <p>We do not engage in the practice of selling, trading, or renting your personal information to third parties.</p>
    <br>
    <p><strong>Compliance with United States Law and International Visitors</strong></p>
    <p>Our Website, the data we collect, and our data processing activities are all governed by United States law. If you access our Website from a location outside of the United States, be aware that your information may be transferred to, stored in, and processed within the United States in accordance with applicable laws.</p>
    <br>
    <p><strong>Policy Updates</strong></p>
    <p>We reserve the right to make amendments to our Privacy Policy as required and will post any changes on this page. If we make significant changes concerning how we manage our users' personal information, we will inform you through an email or a notice on our Website. The Policy's last revision date is marked at the top of this page. You are responsible for ensuring that we have an up-to-date active email address for you and for regularly reviewing this Privacy Policy on our Website.</p>
    <br>
    <p><strong>Contact Information</strong></p>
    <p>Should you have any queries regarding this Privacy Policy or our privacy practices, please feel free to contact us at:</p>
    <br>
    <p>LightWeb Media<br />3410 Belle Chase Way, Ste 600<br />Lansing, MI 48911<br /><br /></p>
  </div>
</template>